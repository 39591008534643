import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/getPaginated';

export const index = (page, perPage, search, sortBy, descending, params) => {
  return getPaginated('employer', { page, perPage, search, sortBy, descending, params });
};

export const autocomplete = (data) => getPaginated('employer/autocomplete', data);

export const actionableCanCreatePriceAgreement = (employerId) => {
  return post('actionable/employer-can-create-price-agreement', { employerId });
};

/**
 * @param employerId {number}
 */
function show(employerId) {
  return get(`employer/${employerId}`);
}

/**
 * @param employer {Employer}
 */
function create(employer) {
  return post('employer', employer);
}

/**
 * @param employer {Employer}
 */
function update(employer) {
  return put(`employer/${employer.id}`, employer);
}

/**
 * @param employerId {number}
 */
function remove(employerId) {
  return destroy(`employer/${employerId}`);
}

function locationTree(employerId) {
  return get(`employer/${employerId}/location/tree`);
}

export {
  show,
  create,
  update,
  remove,
  locationTree,
};
