var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('k-title',[_vm._v(_vm._s(_vm.employer.name))])]},proxy:true},{key:"flow-actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","depressed":"","tile":""},on:{"click":_vm.openUpdate}},[_vm._v(" "+_vm._s(_vm.$t('actions.updateResource', { resource: _vm.$tc('employer.title') }))+" ")]),_c('EmployerForm',{attrs:{"request":_vm.updateRequest,"title":_vm.$tc('employer.title'),"values":_vm.updateFormValues,"is-update-form":""},on:{"change":_vm.getEmployer},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}})]},proxy:true},(_vm.employer)?{key:"default",fn:function(){return [_c('v-row',[_c('QuickInformation',{attrs:{"employer":_vm.employer}}),_c('InternalNotesWidget',{attrs:{"items-per-page":_vm.itemsPerPage}}),_c('ContactWidget',{attrs:{"resource":"contact","visible-columns":[
          'name',
          'phone',
          'email',
        ],"link-to":{ name: 'employer.persons' },"cols":6}}),_c('ContactInformation',{attrs:{"employer":_vm.employer,"link-to":{ name: 'employer.location.index' }}}),_c('InvoiceWidget',{attrs:{"resource":"invoice","visible-columns":[
          'invoiceNumber',
          'sender',
          'receiver',
          'invoiceTypeId',
          {
            align: 'end',
            value: 'invoiceDate',
          },
          {
            align: 'end',
            value: 'expiresAt',
          },
          {
            align: 'center',
            value: 'expiresAtDifference',
          },
          {
            align: 'end',
            value: 'total',
          },
        ],"link-to":{ name: 'employer.invoices' },"cols":12}}),_c('PlacementWidget',{attrs:{"resource":"placement","visible-columns":['candidateName', 'employerName', 'positionName', 'grossHourlyWage', 'startDate', 'endDate'],"link-to":{ name: 'employer.placement' },"cols":12}})],1)]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }