<template>
  <k-field-group language-prefix="employer.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="panels"
      :is-update-form="isUpdateForm"
      v-on="$listeners"
    >
      <template #panel.0>
        <v-row no-gutters>
          <KTextField
            v-model="values.name"
            field="name"
            grid="col-sm-6 pr-2"
            required
          />
        </v-row>
        <v-row no-gutters>
          <IntermediaryUserAutocomplete
            v-model="values.assignedUsers"
            :fill-default-user="isUpdateForm"
            field="assignedUsers"
            grid="col-sm-6 pr-2"
            multiple
          />
        </v-row>
        <v-row class="ma-0">
          <KSelect
            v-model="values.employerTypeId"
            v-bind="$attrs"
            :items="employerStatusOptions"
            field="employerStatus"
            required
            grid="col-sm-6 pr-2"
          />
        </v-row>
      </template>
      <template #panel.1>
        <v-row no-gutters>
          <v-col sm="6">
            <Address v-model="values.address" />
          </v-col>
        </v-row>
      </template>
      <template #panel.2>
        <v-row no-gutters>
          <v-col sm="6">
            <Contact
              v-model="values.contactDetail"
              :fields="employerContactFields"
            />
          </v-col>
        </v-row>
      </template>
      <template #panel.3>
        <v-row no-gutters>
          <v-col
            sm="6"
            class="pr-4"
          >
            <KTextField
              v-if="isUpdateForm && values.relationNumber"
              v-model="values.relationNumber"
              field="relationNumber"
              disabled
            />
            <KTextField
              v-model="values.iban"
              :rules="[validIban]"
              field="iban"
            />
            <KTextField
              v-model="values.ibanAscription"
              field="ibanAscription"
            />
            <KTextField
              v-model="values.vatNumber"
              field="vatNumber"
            />
            <KTextField
              v-model="values.chamberOfCommerceNumber"
              :rules="[validChamberOfCommerceNumber]"
              field="chamberOfCommerceNumber"
            />
            <KTextField
              v-model="values.attn"
              field="attn"
            />
            <KCheckbox
              v-model="values.hasVat"
              field="hasVat"
            />
            <KSelect
              v-model="values.termOfPayment"
              field="termOfPayment"
              :items="[7, 14, 30, 60, 90]"
              required
              type="number"
              :suffix="$tc('global.timeUnits.day', values.termOfPayment)"
            />
            <KTextField
              v-model="values.costCenter"
              field="costCenter"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pr-4">
            <KCheckbox
              v-model="values.hasManualFinancialAddress"
              :false-value="true"
              field="inheritAddress"
              :true-value="false"
            />
            <Address
              v-model="values.financialAddress"
              :disabled="!values.hasManualFinancialAddress"
              validation-field-prefix="financialAddress"
            />
          </v-col>
          <v-col class="px-2">
            <KCheckbox
              v-model="values.hasManualFinancialContactDetail"
              :false-value="true"
              field="inheritContact"
              :true-value="false"
            />
            <Contact
              v-model="values.financialContactDetail"
              :disabled="!values.hasManualFinancialContactDetail"
              :fields="employerContactFields"
              validation-field-prefix="financialContactDetail"
            />
            <v-tooltip
              :disabled="!!employerId"
              right
            >
              <template #activator="{ on }">
                <div v-on="on">
                  <PersonAutocomplete
                    v-model="values.additionalEmails.cc"
                    :disabled="!employerId"
                    :employer-id="employerId"
                    :return-object="false"
                    show-detail="email"
                    multiple
                    field="emailCc"
                  />
                </div>
              </template>
              <span>{{ $t('employer.messages.employerNotYetCreated') }}</span>
            </v-tooltip>
            <KTextField
              v-model="values.additionalEmails.bcc"
              :rules="[validEmail]"
              field="emailBcc"
            />
          </v-col>
        </v-row>
      </template>
      <template #panel.4>
        <v-row no-gutters>
          <v-col sm="6">
            <v-row no-gutters>
              <KTextField
                v-model="values.employeeCount"
                field="employeeCount"
                grid="col-sm-4"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <KFileField
            v-model="values.logo"
            field="logo"
            grid="col-sm-6 mr-2"
          />
        </v-row>
        <v-row no-gutters>
          <KTextarea
            v-model="values.about"
            field="about"
            grid="col-sm-6"
          />
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import employerStatuses from '@/application/enums/employerStatus.json';
import AddressModel from '@/application/models/Address.js';
import ContactModel from '@/application/models/Contact';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFileField from '@/components/crud/fields/KFileField.vue';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import Address from '@/modules/address/components/AddressForm.old.vue';
import Contact from '@/modules/contactDetail/components/ContactForm.vue';
import IntermediaryUserAutocomplete from '../../intermediaryUser/components/IntermediaryUserAutocomplete.vue';
import PersonAutocomplete from '@/modules/person/components/PersonAutocomplete.vue';
import validEmail from '@/application/util/inputFieldRules/validEmail';
import validChamberOfCommerceNumber from '@/application/util/inputFieldRules/validChamberOfCommerceNumber';
import validIban from '@/application/util/inputFieldRules/validIban';

export default {
  name: 'EmployerForm',
  components: {
    KSelect,
    KCheckbox,
    IntermediaryUserAutocomplete,
    KTextarea,
    Contact,
    Address,
    KFieldGroup,
    KTextField,
    KFormDialog,
    KFileField,
    PersonAutocomplete,
  },
  props: {
    values: {
      type: Object,
    },
    isUpdateForm: {
      type: Boolean,
    },
  },
  computed: {
    employerContactFields() {
      return [{
        name: 'email',
        settings: { required: true },
      }, {
        name: 'phone',
        settings: {
          localePrefix: 'employer.fields.contact',
          required: true,
        },
      }, { name: 'fax' }, { name: 'website' }];
    },
    panels() {
      return [
        { name: this.$t('employer.tabs.default') },
        { name: this.$t('employer.tabs.address') },
        { name: this.$t('employer.tabs.contact') },
        { name: this.$t('employer.tabs.finance') },
        { name: this.$t('employer.tabs.profile') },
      ];
    },
    employerStatusOptions() {
      return Object.values(employerStatuses).map(key => {
        return {
          value: key,
          text: this.$t(`employer.fields.employerStatusEnums.${key}`),
        };
      });
    },
    employerId() {
      return this.values?.id;
    },
  },
  watch: {
    'values.contactDetail': {
      deep: true,
      handler(value) {
        if (!this.values.hasManualFinancialContactDetail) {
          this.values.financialContactDetail = new ContactModel().mapResponse(value);
        }
      },
    },
    'values.address': {
      deep: true,
      handler(value) {
        if (!this.values.hasManualFinancialAddress) {
          this.values.financialAddress = new AddressModel().mapResponse(value);
        }
      },
    },
    'values.hasManualFinancialAddress'(value) {
      if (!value) {
        this.values.financialAddress = new AddressModel().mapResponse(this.values.address);
      }
    },
    'values.hasManualFinancialContactDetail'(value) {
      if (!value) {
        this.values.financialContactDetail = new ContactModel().mapResponse(this.values.contactDetail);
      }
    },
  },
  methods: {
    validEmail,
    validIban,
    validChamberOfCommerceNumber,
  },
};
</script>
