import { get } from '@/application/api/implementations/app';

/**
 * @param locationId {number}
 */
function fetchEmployerLocation(locationId) {
  return get(`widget/employer/location/${locationId}`);
}

/**
 * @param departmentId {number}
 */
function fetchDepartmentContact(departmentId) {
  return get(`widget/employer/contact/department/${departmentId}`);
}

/**
 * @param locationId {number}
 */
function fetchLocationContact(locationId) {
  return get(`widget/employer/contact/location/${locationId}`);
}

export {
  fetchEmployerLocation,
  fetchLocationContact,
  fetchDepartmentContact,
};
