import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

function index(employerId, page, perPage, search, sortBy, descending, params) {
  return getPaginated(`employer/${employerId}/location`, page, perPage, search, sortBy, descending, params);
}

function autocomplete(employerId, page, perPage, search, sortBy, descending, params) {
  return getPaginated(`employer/${employerId}/location/autocomplete`, page, perPage, search, sortBy, descending, params);
}

/**
 * @param locationId {number}
 */
function show(locationId) {
  return get(`location/${locationId}`);
}

/**
 * @param employerId {number}
 * @param location {Location}
 */
function create(employerId, location) {
  return post(`employer/${employerId}/location`, location);
}

/**
 * @param location {Location}
 */
function update(location) {
  return put(`location/${location.id}`, location);
}

/**
 * @param locationId {number}
 */
function remove(locationId) {
  return destroy(`location/${locationId}`);
}

export {
  index,
  show,
  create,
  update,
  remove,
  autocomplete,
};
