const contentTypes = {
  'application/pdf': 'pdf',
  'image/png': 'png',
  'image/jpeg': 'jpg',
} as const;

type ContentType = keyof typeof contentTypes;
type Extension = typeof contentTypes[ContentType];

interface Options {
  fallback: Extension;
}

export default (contentType: ContentType, options: Partial<Options> = {}): Extension => {
  const extension = contentTypes[contentType];

  if (!extension && options.fallback) return options.fallback;

  return extension;
};
