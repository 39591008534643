import { render, staticRenderFns } from "./PlacementWidget.vue?vue&type=template&id=d356d352&scoped=true&"
import script from "./PlacementWidget.vue?vue&type=script&lang=js&"
export * from "./PlacementWidget.vue?vue&type=script&lang=js&"
import style0 from "./PlacementWidget.vue?vue&type=style&index=0&id=d356d352&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d356d352",
  null
  
)

export default component.exports