import getExtensionFromContentType from '@/application/util/getExtensionFromContentType';

export default async function downloadFile(fetcher, fileName) {
  const response = await fetcher;
  const url = window.URL.createObjectURL(new Blob([response.data]));
  
  const extension = getExtensionFromContentType(response.data.type, {
    fallback: 'pdf',
  });
  
  const link = window.document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.setAttribute('download', `${fileName}.${extension}`);
  window.document.body.appendChild(link);
  link.click();
}
