
import Vue, { PropType } from 'vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KLinkDisplay from '@/components/crud/display/KLinkDisplay.vue';
import DepartmentAutocomplete from '@/modules/department/components/DepartmentAutocomplete.vue';
import { fetchDepartmentContact, fetchLocationContact } from '@/modules/employer/api/widget.js';
import LocationAutocomplete from '@/modules/location/components/LocationAutocomplete.vue';

interface ComponentData {
    location: ContactData | null,
    department: ContactData | null,
    contactData: ContactData | null,
}

interface Address {
  street: string;
  houseNumber: string;
  houseNumberAddition: string;
  zipCode: string;
  city: string;
}

interface ContactDetail {
  phone: string;
  fax: string;
  email: string;
}

interface ContactData extends ContactDetail {
  id: number;
  address: Address;
}

interface Employer {
  id: number,
  contactDetail: ContactDetail;
  address: Address;
}

export default Vue.extend({
  name: 'ContactInformation',
  components: {
    KLinkDisplay,
    KDisplayGroup,
    DepartmentAutocomplete,
    LocationAutocomplete,
  },
  props: {
    linkTo: {
      type: Object as PropType<string>,
      default: null,
    },
    employer: {
      type: Object as PropType<Employer>,
    },
  },
  data: (): ComponentData => ({
    location: null,
    department: null,
    contactData: null,
  }),

  computed: {
    employerContactData(): ContactData {
      return {
        id: this.employer.id,
        ...this.employer.contactDetail,
        address: this.employer.address,
      };
    },
    mapsUrl() {
      if (!this.contactData?.address) return;
      
      const url = new URL('https://www.google.com/maps/embed/v1/search');
      const { street, houseNumber, houseNumberAddition, zipCode, city } = this.contactData.address;
      url.searchParams.set('q', [street, houseNumber, houseNumberAddition, zipCode, city].join(' '));
      url.searchParams.set('key', process.env.VUE_APP_API_KEY_GOOGLE_MAPS);

      return url.href;
    },
  },
  created() {
    this.contactData = this.employerContactData;
  },
  methods: {
    async locationSelected() {
      if (!this.location) {
        this.contactData = this.employerContactData;
        return;
      }
      
      const response = await fetchLocationContact(this.location.id);
      this.contactData = response.data.data;
    },
    async departmentSelected() {
      if (!this.department) {
        this.locationSelected();
        return;
      }

      const response = await fetchDepartmentContact(this.department.id);
      this.contactData = response.data.data;
    },
  },
});
