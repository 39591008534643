import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = async (...args) => {
  const response = await getPaginated('invoice', ...args);
  if (response?.data?.data) response.data.data.forEach(item => item.total /= 100);
  return response;
};

export const document = (invoiceId) => get(`invoice/${invoiceId}/document`, { responseType: 'blob' });

export const downloadTimesheet = (invoiceId) => get(`invoice/${invoiceId}/timesheet/document`, { responseType: 'blob' });

export const generatePdf = (invoiceId) => get(`invoice/${invoiceId}/document?format=json`);

export const send = (invoiceId) => post(`invoice/${invoiceId}/send`);

export const details = (invoiceId) => get(`invoice/${invoiceId}/details`);

export const concept = (invoiceId) => get(`/invoice/${invoiceId}/concept`);

export const external = (invoiceId) => get(`/invoice/${invoiceId}/external`);

export const retryExact = (invoiceId) => get(`invoice/${invoiceId}/retry-exact`);

export const status = (invoiceId, invoiceStatusId) => post(`invoice/${invoiceId}/status`, { invoiceStatusId });

export const createManualInvoice = (invoice) => post('manual-invoice', invoice);

export const show = (invoiceId) => get(`manual-invoice/${invoiceId}`);

export const updateManualInvoice = (invoice, invoiceId) => put(`manual-invoice/${invoiceId}`, invoice);

export const candidateAutocomplete  = (...args) =>
  getPaginated('manual-invoice/candidate/autocomplete', ...args);
  
export const invoiceReferences = (params) => get('manual-invoice/invoice/autocomplete', { params });

export const calculateTotals = (invoiceLines) => {
  return post('manual-invoice/calculate-totals', invoiceLines);
};
