import Address from '@/application/models/Address.js';
import Contact from '@/application/models/Contact';
import Model from '@/application/models/model.js';
import formatRelationNumber from '@/application/helpers/formatRelationNumber';
import mapFileForRequest from '@/application/util/mapFileForRequest';

class Employer extends Model {
  about = '';
  address = new Address();
  contactDetail = new Contact();
  chamberOfCommerceNumber = '';
  employeeCount = '';
  financialAddress = new Address();
  financialContactDetail = new Contact();
  hasManualFinancialAddress = false;
  hasManualFinancialContactDetail = false;
  additionalEmails = {
    cc: [],
    bcc: '',
  }
  iban = '';
  ibanAscription = '';
  id = 0;
  name = '';
  slug = '';
  vatNumber = '';
  logo = null;
  assignedUsers = [];
  attn = '';
  relationNumber = 2000000;
  hasVat = false;
  termOfPayment = 0;
  costCenter = '';
  employerTypeId = null;

  mapForRequest() {
    return {
      about: this.about,
      address: this.address.mapForRequest(),
      contactDetail: this.contactDetail.mapForRequest(),
      chamberOfCommerceNumber: this.chamberOfCommerceNumber,
      employeeCount: this.employeeCount,
      financialAddress: this.financialAddress.mapForRequest(),
      financialContactDetail: this.financialContactDetail.mapForRequest(),
      additionalEmails: this.additionalEmails,
      iban: this.iban,
      ibanAscription: this.ibanAscription,
      id: this.id,
      hasManualFinancialAddress: this.hasManualFinancialAddress,
      hasManualFinancialContactDetail: this.hasManualFinancialContactDetail,
      name: this.name,
      slug: this.slug,
      vatNumber: this.vatNumber,
      logo: mapFileForRequest(this.logo),
      assignedUsers: this.assignedUsers.map((item) => item.id),
      attn: this.attn,
      hasVat: this.hasVat,
      termOfPayment: this.termOfPayment,
      costCenter: this.costCenter,
      employerTypeId: this.employerTypeId,
    };
  }

  mapResponse(data) {
    const employer = super.mapResponse({ ...data });
    
    employer.relationNumber = formatRelationNumber(employer.relationNumber);

    return employer;
  }
}

export default Employer;
