<template>
  <PageLayout>
    <template #header>
      <k-title>{{ employer.name }}</k-title>
    </template>
    <template #flow-actions>
      <v-btn
        color="primary"
        depressed
        tile
        @click="openUpdate"
      >
        {{ $t('actions.updateResource', { resource: $tc('employer.title') }) }}
      </v-btn>
      <EmployerForm
        v-model="updateDialog"
        :request="updateRequest"
        :title="$tc('employer.title')"
        :values="updateFormValues"
        is-update-form
        @change="getEmployer"
      />
    </template>
    <template
      v-if="employer"
      #default
    >
      <v-row>
        <QuickInformation
          :employer="employer"
        />
        <InternalNotesWidget
          :items-per-page="itemsPerPage"
        />
        <ContactWidget
          resource="contact"
          :visible-columns="[
            'name',
            'phone',
            'email',
          ]"
          :link-to="{ name: 'employer.persons' }"
          :cols="6"
        />
        <ContactInformation
          :employer="employer"
          :link-to="{ name: 'employer.location.index' }"
        />
        <InvoiceWidget
          resource="invoice"
          :visible-columns="[
            'invoiceNumber',
            'sender',
            'receiver',
            'invoiceTypeId',
            {
              align: 'end',
              value: 'invoiceDate',
            },
            {
              align: 'end',
              value: 'expiresAt',
            },
            {
              align: 'center',
              value: 'expiresAtDifference',
            },
            {
              align: 'end',
              value: 'total',
            },
          ]"
          :link-to="{ name: 'employer.invoices' }"
          :cols="12"
        />
        <PlacementWidget
          resource="placement"
          :visible-columns="['candidateName', 'employerName', 'positionName', 'grossHourlyWage', 'startDate', 'endDate']"
          :link-to="{ name: 'employer.placement' }"
          :cols="12"
        />
      </v-row>
    </template>
  </PageLayout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Employer from '@/application/models/Employer.js';
import PageLayout from '@/components/layout/PageLayout.vue';
import { show, update } from '@/modules/employer/api/employer.js';
import { landingPageMode } from '@/application/enums/landingPageMode';
import EmployerForm from '@/modules/employer/components/EmployerForm.vue';
import KTitle from '@/components/layout/KTitle.vue';
import { mapGetters } from 'vuex';
import QuickInformation from '@/components/widgets/employer/QuickInformation.vue';
import ContactInformation from '@/components/widgets/employer/ContactInformation.vue';
import InvoiceWidget from '@/modules/dashboard/widgets/InvoiceWidget.vue';
import PlacementWidget from '@/modules/dashboard/widgets/PlacementWidget.vue';
import InternalNotesWidget from '@/modules/dashboard/widgets/InternalNotesWidget.vue';
import ContactWidget from '@/modules/dashboard/widgets/ContactWidget.vue';

export default {
  name: 'Details',
  components: {
    KTitle,
    EmployerForm,
    PageLayout,
    QuickInformation,
    ContactInformation,
    InvoiceWidget,
    PlacementWidget,
    InternalNotesWidget,
    ContactWidget,
  },
  props: {
    employer: {
      type: Object,
    },
    getEmployer: {
      type: Function,
    },
  },
  data() {
    return {
      updateFormValues: new Employer(),
      updateDialog: false,
      itemsPerPage: 5,
      landingPageMode: landingPageMode.SINGLE_ORGANISATION,
    };
  },
  computed: {
    ...mapGetters({ can: 'authorisation/can' }),
  },
  watch: {
    '$route.params.employerId': {
      immediate: true,
      handler() {
        if (this.can('employer.internalNote')) {
          eventBus.$emit('internalNote', {
            scope: 'employer',
            scopeId: this.employer.id,
          });
        }
      },
    },
  },
  beforeDestroy() {
    eventBus.$emit('internalNote');
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('employer.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      }],
    );
  },
  methods: {
    updateRequest() {
      return update(this.updateFormValues);
    },
    async openUpdate() {
      this.updateFormValues = new Employer();
      const response = await show(this.employer.id);
      this.updateFormValues.mapResponse(response.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
