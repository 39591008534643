<template>
  <v-row no-gutters>
    <KTextField
      v-model="address.zipCode"
      :disabled="disabled"
      :required="required && !disabled"
      :validation-field="`${validationFieldPrefix}.zipCode`"
      field="address.zipCode"
      grid="col-sm-5 pr-2"
      @input="getAddress"
    />
    <KTextField
      v-model="address.houseNumber"
      :disabled="disabled"
      :required="required && !disabled"
      :validation-field="`${validationFieldPrefix}.houseNumber`"
      field="address.houseNumber"
      grid="col-sm-4 pr-2"
      @input="getAddress"
    />
    <KTextField
      v-model="address.houseNumberAddition"
      :disabled="disabled"
      :validation-field="`${validationFieldPrefix}.houseNumberAddition`"
      field="address.houseNumberAddition"
      grid="col-sm-3"
      @input="getAddress"
    />
    <KTextField
      v-model="address.street"
      :disabled="disabled"
      :required="required && !disabled"
      :validation-field="`${validationFieldPrefix}.street`"
      field="address.street"
    />
    <KTextField
      v-model="address.city"
      :disabled="disabled"
      :required="required && !disabled"
      :validation-field="`${validationFieldPrefix}.city`"
      field="address.city"
    />
    <KSelect
      v-model="address.countryId"
      :disabled="disabled"
      :items="countries"
      :required="required && !disabled"
      :validation-field="`${validationFieldPrefix}.countryId`"
      class="col-sm-12"
      field="address.countryId"
      item-text="name"
      item-value="id"
    />
  </v-row>
</template>

<script>
import country from '@/application/enums/country';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import findByZipCode from '@/application/util/findByZipCode';

export default {
  name: 'Address',
  components: {
    KSelect,
    KTextField,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validationFieldPrefix: {
      default: 'address',
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    address: {},
    addressTimeout: 0,
    addressCache: {},
  }),
  computed: {
    countries() {
      return Object.keys(country).map((name) => ({
        name: this.$t(`country.${name}`),
        id: country[name],
      }));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.address = this.value;
      },
    },
    address() {
      this.$emit('input', this.address);
    },
  },
  methods: {
    getAddress() {
      clearTimeout(this.addressTimeout);
      this.addressTimeout = setTimeout(async () => {
        const {
          zipCode,
          houseNumber,
          houseNumberAddition,
        } = this.address;
        
        if (!zipCode || !houseNumber) return;
        
        const locationId = `${zipCode}||${houseNumber}${houseNumberAddition}`;

        if (this.addressCache[locationId]) {
          this.address.street = this.addressCache[locationId].street;
          this.address.city = this.addressCache[locationId].city;
          return;
        }

        const response = await findByZipCode({ zipCode, houseNumber, houseNumberAddition });

        if (!response.data.data?.address) return;

        this.addressCache[locationId] = {
          street: response.data.data.address.street,
          city: response.data.data.address.city,
        };

        this.address.street = response.data.data.address.street;
        this.address.city = response.data.data.address.city;
      }, 300);
    },
  },
};
</script>
