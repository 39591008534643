<template>
  <v-col
    cols="12"
    :lg="cols"
  >
    <v-sheet class="k-transparent pt-2 px-4 pb-4 fill-height">
      <div class="d-flex justify-space-between mb-2">
        <component
          :is="linkTo ? 'router-link' : 'span'"
          class="text-h3 primary--text"
          :to="linkTo"
        >
          {{ $t(`landingPage.${resource}.title`) }}
        </component>
      </div>
      <k-crud-table
        ref="table"
        :headers="crudHeaders"
        :index-request="indexRequest"
        :language-prefix="`landingPage.${resource}.columns`"
        hide-default-footer
        disable-sort
      >
        <template
          #actions="{ item }"
          class="text-no-wrap"
        >
          <v-btn
            color="text--secondary"
            icon
            @click="$router.push({ name: 'employer.person', params: { personId: item.id } })"
          >
            <v-icon>$visible</v-icon>
          </v-btn>
        </template>
      </k-crud-table>
    </v-sheet>
  </v-col>
</template>

<script>
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import { index } from '@/modules/person/api/person';

export default {
  name: 'ContactWidget',
  components: {
    KCrudTable,
  },
  props: {
    resource: {
      type: String,
      require: true,
    },
    cols: {
      type: Number,
      default: 6,
    },
    visibleColumns: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
    linkTo: {
      type: [Object, null],
      default: null,
    },
  },
  computed: {
    crudHeaders() {
      return this.visibleColumns.map(column => typeof column === 'string' ? { value: column } : column );
    },
  },
  methods: {
    indexRequest(...[page, perPage, search, sortBy, descending, params]) {

      perPage = this.itemsPerPage;

      return index(this.$route.params.employerId, ...[page, perPage, search, sortBy, descending, params]);
    },
  },
};
</script>
