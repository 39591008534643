<template>
  <v-col
    cols="12"
    :lg="cols"
  >
    <v-sheet class="k-transparent pt-2 px-4 pb-4 fill-height">
      <div class="d-flex justify-space-between mb-2">
        <component
          :is="linkTo ? 'router-link' : 'span'"
          class="text-h3 primary--text"
          :to="linkTo"
        >
          {{ $t(`landingPage.${resource}.title`) }}
        </component>
        <ul class="widget-filters d-flex align-center font-weight-bold primary--text text-button">
          <li
            v-for="option in [...filters.options, { text: 'total' }]"
            :key="option.text"
            class="d-block px-2"
            :class="{ active: activeFilter.text === option.text }"
            @click="setActiveFilter(option)"
          >
            {{ $t(`landingPage.${resource}.filters.${option.text}`) }}
          </li>
        </ul>
      </div>
      <k-crud-table
        ref="table"
        :headers="crudHeaders"
        :index-request="indexRequest"
        :language-prefix="`landingPage.${resource}.columns`"
        hide-default-footer
        disable-sort
      >
        <template #item.invoiceTypeId="{ item: { invoiceTypeId } }">
          {{ $t(`invoice.invoiceType.${getEnumKeyByValue(invoiceType, invoiceTypeId)}`) }}
        </template>
        <template #item.invoiceDate="{ item: { invoiceDate } }">
          {{ invoiceDate ? dayjs(invoiceDate, 'YYYY-MM-DD').format('DD MMM YYYY') : '-' }}
        </template>
        <template #item.expiresAt="{ item: { expiresAt } }">
          <span class="text-no-wrap">{{
            expiresAt ? dayjs(expiresAt, 'YYYY-MM-DD').format('DD MMM YYYY') : '-'
          }}
          </span>
        </template>
        <template #item.expiresAtDifference="{ item: { expiresAt } }">
          <v-chip
            v-if="expiresAt"
            small
            :color="getExpiredDifferenceInDays(expiresAt) >= 0 ? 'red' :'green'"
            text-color="white"
          >
            {{ getExpiredDifferenceInDays(expiresAt) }}
          </v-chip>
        </template>
        <template #item.total="{ item: { total } }">
          {{ $n(total / 100, 'currency') }}
        </template>
        <template #item.invoiceStatusId="{ item: { invoiceStatusId } }">
          {{ getTranslatedEnumValue(invoiceStatus, invoiceStatusId, 'invoice.invoiceStatusId') }}
        </template>

        <template
          #actions="{ item }"
          class="text-no-wrap"
        >
          <v-btn
            :disabled="isFileDownloading.includes(item.id)"
            color="text--secondary"
            icon
            @click.stop="downloadInvoice(item)"
          >
            <v-icon>$filePdf</v-icon>
          </v-btn>
        </template>
      </k-crud-table>
    </v-sheet>
  </v-col>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import { index } from '@/modules/dashboard/api';
import dayjs from '@/plugins/dayjs';
import downloadFile from '@/application/util/downloadFile.js';
import { document } from '@/modules/invoice/api';
import { invoiceType } from '@/application/enums/invoiceType';
import { invoiceStatus } from '@/application/enums/invoiceStatus';
import { getEnumKeyByValue, getTranslatedEnumValue } from '@/application/helpers/enum.js';

export default {
  name: 'InvoiceWidget',
  components: {
    KCrudTable,
  },
  props: {
    resource: {
      type: String,
      require: true,
    },
    assignedToUser: {
      type: Array,
      default: () => [],
    },
    cols: {
      type: Number,
      default: 6,
    },
    visibleColumns: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
    linkTo: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      filters: {
        field: 'invoiceTypeIds',
        options: [
          {
            text: 'purchase',
            value: [3, 5],
          },
          {
            text: 'sales',
            value: [1],
          },
          {
            text: 'margin',
            value: [2],
          },
          {
            text: 'manualInvoice',
            value: [6],
          },
        ],
      },
      activeFilter: { text: 'total' },
      isFileDownloading: [],
    };
  },
  computed: {
    invoiceType: () => invoiceType,
    invoiceStatus: () => invoiceStatus,
    crudHeaders() {
      return this.visibleColumns.map(column => typeof column === 'string' ? { value: column } : column );
    },
  },
  watch: {
    activeFilter() {
      this.$refs.table.reload();
    },
    assignedToUser() {
      this.$refs.table.reload();
    },
  },
  methods: {
    dayjs,
    getEnumKeyByValue,
    getTranslatedEnumValue,
    indexRequest(...[page, perPage, search, sortBy, descending, params]) {

      perPage = this.itemsPerPage;
      params = {
        employerId: this.$route.params.employerId,
        assignedUserIds: this.assignedToUser,
        [this.filters.field]: this.activeFilter.value,
      };

      return index(this.resource, ...[page, perPage, search, sortBy, descending, params]);
    },
    setActiveFilter(filter) {
      this.activeFilter = filter;
    },
    async downloadInvoice(item) {
      try {
        this.isFileDownloading.push(item.id);
        await downloadFile(document(item.id), `${item.invoiceNumber}`);
        this.isFileDownloading = this.isFileDownloading.filter(id => id !== item.id);
      } catch (error) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('invoice.messages.errors.download'),
        });
      }
    },
    getExpiredDifferenceInDays(expiresAt) {
      const expiresAtDateInMilliseconds = new Date(expiresAt).getTime();
      const todayInMilliseconds = new Date().getTime();
      const difference = todayInMilliseconds - expiresAtDateInMilliseconds;
      // return in days
      return Math.floor(difference / (1000 * 3600 * 24));
    },
  },
};
</script>

<style lang="scss" scoped>
ul.widget-filters {
  list-style: none;
  li {
    font-size: 11px;
    opacity: 0.35;
    transition: opacity 120ms ease;
    cursor: pointer;
    &:hover,
    &.active {
      opacity: 1;
    }
  }
}
</style>
