<template>
  <v-col
    cols="12"
    lg="6"
  >
    <v-sheet class="k-transparent pt-2 px-4 pb-4 fill-height">
      <k-display-group language-prefix="employer.fields">
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <KTextDisplay
              field="relationNumber"
              :value="employer.relationNumber"
            />
            <KTextDisplay
              field="costCenter"
              :value="employer.costCenter"
            />
            <KLinkDisplay
              :href="employer.contactDetail.mobilePhone"
              field="contactDetail.mobilePhone"
              link-type="phone"
            />
            <KLinkDisplay
              :href="employer.contactDetail.fax"
              field="contactDetail.fax"
              link-type="fax"
            />
            <KLinkDisplay
              :href="employer.contactDetail.website"
              field="contactDetail.website"
              link-type="website"
            />
            <k-text-display field="address">
              <AddressDisplay :address="employer.address" />
            </k-text-display>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <KTextDisplay
              field="employerStatus"
              :value="$t(`employer.fields.employerStatusEnums.${employer.employerTypeId}`)"
            />
            <KTextDisplay
              field="iban"
              :value="employer.iban"
            />
            <KTextDisplay
              field="ibanAscription"
              :value="employer.ibanAscription"
            />
            <KTextDisplay
              field="termOfPayment"
              :value="`${employer.termOfPayment} ${$tc('global.timeUnits.day', 2)}`"
            />
            <img
              v-if="employer.logo"
              :src="employer.logo.url"
              height="64"
            >
          </v-col>
        </v-row>
      </k-display-group>
    </v-sheet>
  </v-col>
</template>

<script>
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KLinkDisplay from '@/components/crud/display/KLinkDisplay.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import AddressDisplay from '@/modules/address/components/AddressDisplay.vue';

export default {
  name: 'QuickInformation',
  components: {
    KDisplayGroup,
    KLinkDisplay,
    KTextDisplay,
    AddressDisplay,
  },
  props: {
    employer: {
      type: Object,
    },
  },
};
</script>
